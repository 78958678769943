import { render, staticRenderFns } from "./Replies.vue?vue&type=template&id=483613b2&scoped=true&"
import script from "./Replies.vue?vue&type=script&lang=ts&"
export * from "./Replies.vue?vue&type=script&lang=ts&"
import style0 from "./Replies.vue?vue&type=style&index=0&id=483613b2&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483613b2",
  null
  
)

export default component.exports