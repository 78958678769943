















import { Vue, Component, Prop } from "vue-property-decorator";
import ListingCards from "@/components/UIComponents/ListingCards.vue";
import VarietyFilter from "@/components/UIComponents/Filter.vue";
import { CropVarietyFilter, CropVariety } from "@/store/models/cropVariety";
import CropVarietyStore from "@/store/modules/cropVariety";

@Component({
  components: {
    ListingCards: ListingCards,
    VarietyFilter
  }
})
export default class Listingbody extends Vue {
  @Prop({ default: "" })
  cropSlug!: string;

  @Prop({ default: 0 })
  cropId!: number;
  collapsable: any = [0, 1, 2];

  @Prop({ default: [] })
  filterables!: CropVarietyFilter[];

  activeCollapsable: any[] = [];

  get activeName() {
    if (this.activeCollapsable.length <= 0) {
      for (let filter in this.filterables) {
        if (Number(filter) < 3) {
          this.activeCollapsable.push(this.filterables[filter].id);
        }
      }
    }
    return this.activeCollapsable;
  }

  set activeName(value: any) {
    this.activeCollapsable = value;
  }
}
