<template>
  <div class="share d-flex">
    <facebook :url="url" :scale="scale"></facebook>
    <twitter :url="url" :title="title" :scale="scale"></twitter>
    <linkedin :url="url" :scale="scale"></linkedin>
    <!-- <telegram :url="url" :scale="scale"></telegram> -->
    <!-- <whats-app :url="url" :title="title" :scale="scale"></whats-app> -->
    <!-- <pinterest :url="url" :scale="scale"></pinterest> -->
    <!-- <reddit :url="url" :scale="scale" :title="title"></reddit> -->
    <!-- <google :url="url" :scale="scale"></google> -->
    <email :url="url" :subject="title" :scale="scale"></email>
  </div>
</template>

<script>
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  Email,
  Google
} from "vue-socialmedia-share";
export default {
  name: "Share",
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Reddit,
    Telegram,
    WhatsApp,
    Email,
    Google
  },
  props: {
    url: String,
    scale: String,
    title: String
  }
};
</script>

<style lang="sass" scoped>
.share > span
  padding: 0.3em
  cursor: pointer

</style>
