<template>
  <div class="slider" ref="slider">
    <LightGallery :images="slides" :index="index" @close="index = null"> </LightGallery>
    <vueper-slides
      class="no-shadow"
      :arrows="true"
      :slide-ratio="1 / 8"
      :dragging-distance="50"
      :breakpoints="{
        630: { visibleSlides: 2 },
        450: { visibleSlides: 1 },
        850: { visibleSlides: 3 }
      }"
    >
      <vueper-slide
        v-for="(item, i) in slides"
        :key="item.id"
        :image="item.image"
        :title="item.caption"
        @click.native="changeIndex(i)"
      >
        <!-- <div slot="slideContent">
          <el-button @click="changeIndex(i)" icon="el-icon-view" class="viewicon"></el-button>
        </div> -->
      </vueper-slide>
    </vueper-slides>
    <!-- @click.native="index = item.index" -->
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import CropStore from "@/store/modules/crop";
import VueGallery from "vue-gallery";
import { LightGallery } from "vue-light-gallery";

export default {
  components: { VueperSlides, VueperSlide, gallery: VueGallery, LightGallery },
  props: {
    slides: Array,
    captionList: Array
  },
  data() {
    return {
      noOfSlides: 3,
      hover: false,
      draggable: false,
      index: null
    };
  },
  methods: {
    changeIndex(i) {
      this.index = i;
    }
  },
  created() {},
  mounted() {
    // var width = this.$refs.slider.clientWidth;
    // this.noOfSlides = parseInt(width / 200);
  }
};
</script>

<style lang="sass">

.vueperslides__track-inner
  a
    border-left: 6px solid #ffffff
    border-right: 6px solid #ffffff

.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom)
    bottom: 5px !important

.vueperslides__arrows--outside .vueperslides__arrow--prev
  left: 0px !important

.vueperslide__content, .vueperslide__title
  color: white
  padding: 5px

.vueperslides__arrow, .vueperslides__paused
    margin-left: -10px
    margin-right: -13px

.vueperslides__arrow--next svg
  width: 26px

.vueperslides__arrow--prev svg
  width: 26px
  // margin-

a.vueperslide
  transition: all .5s
  &:hover
    transform: scale(1.1)

.vueperslides__track a
  &:before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    &:hover
      background-color: none

a.close
  color: #000

.viewicon
    background: transparent
    color: white
    border: 0
    font-size: 19px
    padding: 5px

.cropdetail__gallery .cropdetail__imageone
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
  width: 100%
  height: 200px
</style>
