









































































import { Vue, Component, Prop } from "vue-property-decorator";
import { JWTStore, CommentStore } from "@/store/modules";
import { CropVarietyComment } from "@/store/models/comment";
import UserStore from "@/store/modules/user";
import { User } from "@/store/models/user";
import moment from "moment";

@Component({
  components: {}
})
export default class Replies extends Vue {
  @Prop({ default: {} as CropVarietyComment })
  comment!: CropVarietyComment;

  currentReply: CropVarietyComment = {} as CropVarietyComment;
  dialogEditReplyFormVisible = false;

  get repliesFromStore() {
    return CommentStore.ReplyList;
  }

  get replies() {
    return this.repliesFromStore[String(this.comment.id)];
  }

  get user() {
    return UserStore.User;
  }

  get isLoggedIn() {
    return JWTStore.isAuthenticated;
  }

  editReply(replyid: number) {
    let replyToEdit = this.replies.filter(x => x.id == replyid)[0];
    this.currentReply = Object.assign({}, replyToEdit);
    this.dialogEditReplyFormVisible = true;
  }

  removeReply(replyid: number) {
    this.$confirm("This will permanently delete this reply. Continue?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "el-button--danger",
      cancelButtonText: "Cancel",
      showClose: false,
      type: "warning"
    })

      .then(async () => {
        CommentStore.deleteReply({ id: replyid, parentid: this.comment.id });
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
  }

  async onSubmit() {
    if (
      this.currentReply.comment === undefined ||
      this.currentReply.comment.length === 0 ||
      this.currentReply.comment === null
    ) {
      return;
    }
    await CommentStore.saveReply(this.currentReply);
    this.currentReply = {} as CropVarietyComment;
    this.dialogEditReplyFormVisible = false;
  }

  replyMoment(date: string) {
    return moment(new Date(date)).fromNow();
  }
}
