var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cropdetail__info"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-lg-4 col-sm-12 col-xs-12"},[_c('div',{staticClass:"cropdetail__gallery"},[(_vm.index != null && _vm.title)?_c('div',{staticStyle:{"position":"fixed","z-index":"9999999","color":"white","x":"100","left":"1vw","top":"1vw"}},[_c('strong',[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('Detailinfoslider',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"captionList":_vm.captionList,"index":_vm.index,"slides":_vm.imagearray}}),_c('br'),_c('h5',{staticClass:"bolder green"},[_vm._v("Supplier Details")]),_c('hr'),_vm._l((_vm.suppliers.results),function(supplier,index){return _c('span',{key:index},[_c('router-link',{attrs:{"to":{ name: 'SupplierDetail', params: { userSlug: supplier.companyslug } }}},[_c('h6',{staticClass:"bolder"},[_vm._v(_vm._s(supplier.companyname))])]),_c('p',{staticStyle:{"font-size":"0.8rem !important"}},[_vm._v(_vm._s(supplier.companyaddress))])],1)}),_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-count":_vm.suppliers.total_pages,"background":"","layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.pageNum=$event},"update:current-page":function($event){_vm.pageNum=$event},"current-change":_vm.handlePageChange}})],2)]),_c('div',{staticClass:"col-md-8 col-lg-8 col-sm-12 col-xs-12"},[_c('div',{staticClass:"flex flexColumn"},_vm._l((_vm.cropVariety.meta),function(variables,characteristics){return _c('div',{key:characteristics,staticClass:"infoBottom"},[(_vm.checkVariablesExists(variables))?_c('div',[_c('h5',{staticClass:"bolder green"},[_vm._v(_vm._s(characteristics)+":")])]):_vm._e(),_vm._l((variables),function(variable){return _c('div',{key:variable.id,staticClass:"cropdetail__info-section"},[(variable.Data.value)?_c('span',[_c('div',{staticClass:"flex"},[_c('p',[(
                      variable.cropvarietiesmetavarname == 'Pre-released variety' ||
                        variable.cropvarietiesmetavarname == 'पूर्व जारी विविधता'
                    )?_c('span',[(variable.Data.value == 'Yes (हो)')?_c('span',[_c('strong',[_vm._v(" "+_vm._s(variable.cropvarietiesmetavarname)+" : ")]),_c('em',[_vm._v(_vm._s(variable.Data.value))])]):_vm._e()]):(variable.controltype == 'link')?_c('span',[_c('strong',[_vm._v(" "+_vm._s(variable.cropvarietiesmetavarname)+" : ")]),(variable.Data.value.split('//')[0].includes('http'))?_c('span',[_c('a',{attrs:{"href":variable.Data.value,"target":"_blank"}},[_vm._v(_vm._s(variable.Data.value.split("//")[1]))])]):_c('span',[_c('a',{attrs:{"href":("http://" + (variable.Data.value)),"target":"_blank"}},[_vm._v(_vm._s(variable.Data.value))])])]):(
                      variable.cropvarietiesmetavarname == 'Special Description' ||
                        variable.cropvarietiesmetavarname == 'विशेष विवरण'
                    )?_c('span',[_c('pre',{staticStyle:{"font-family":"'Open Sans', sans-serif, 'Arial'","font-size":"1em","color":"#000000"}},[_vm._v(_vm._s(variable.Data.value))])]):_c('span',[_c('strong',[_vm._v(" "+_vm._s(variable.cropvarietiesmetavarname)+" : ")]),(
                        variable.cropvarietiesmetavarname == 'Botanical Name' ||
                          variable.cropvarietiesmetavarname == 'वानस्पतिक नाम'
                      )?_c('span',[_c('em',[_vm._v(_vm._s(variable.Data.value.toLowerCase()))])]):_c('span',[_vm._v(_vm._s(variable.Data.value))]),(variable.unit)?_c('span',[_vm._v(" "+_vm._s(variable.unit))]):_vm._e()])])])]):_vm._e()])})],2)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }