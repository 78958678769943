



















































import { Vue, Component, Prop } from "vue-property-decorator";
import CropVarietyStore from "@/store/modules/cropVariety";
import LoadStore from "@/store/modules/loader";
import CropVarietyValue from "../../views/Admin/CropVarietyValue.vue";

@Component({
  components: {}
})
export default class Varities extends Vue {
  @Prop({ default: "" })
  varietyName!: string;

  private get similarCropVariety() {
    return CropVarietyStore.SimilarCropVarieties;
  }

  async toDetailScreen(id: number, varietySlug: string) {
    this.$router
      .push({
        name: "DetailScreen",
        params: { varietySlug: String(varietySlug) }
      })
      .catch(error => {});

    CropVarietyStore.getSimilarCropVariety({
      cropId: id,
      lang: this.$i18n.locale
    });
    CropVarietyStore.getImagesByVarietyId(id);
    // CropVarietyStore.getCropVarietyCommentsById(id);

    LoadStore.showLoadingCropVarietyDetail();
    await CropVarietyStore.getCropVarietyDetailById({
      id: id,
      lang: this.$i18n.locale
    });
    // CropVarietyStore.getCropVarietyCommentsById(id);
    LoadStore.hideLoadingCropVarietyDetail();
  }

  toOtherVarietiesList() {
    this.$router
      .push({
        name: "VarietyList",
        query: {
          varietyId: this.$route.query.varietyId
        }
      })
      .catch(error => {});
    CropVarietyStore.getSimilarCropVariety({
      cropId: Number(this.$route.query.varietyId),
      lang: this.$i18n.locale
    });
  }
}
