














































































import { Vue, Component, Prop } from "vue-property-decorator";
import CropVarietyStore from "@/store/modules/cropVariety";
import compareCrop from "@/store/modules/compareCrop";

@Component({
  components: {}
})
export default class ListingCards extends Vue {
  checkedCrops: boolean = false;
  // @Prop()
  // varieties!: [];
  @Prop({ default: "" })
  cropSlug!: string;

  get checkedIds() {
    return compareCrop.selectedCrops;
  }

  get cropId() {
    return CropVarietyStore.cropIdInList;
  }

  set cropId(id: number) {
    CropVarietyStore.setCropIdInList(id);
  }

  get pageNum() {
    return CropVarietyStore.pageNumber;
  }

  set pageNum(page: number) {
    CropVarietyStore.setPageNumber(page);
  }

  private get varieties() {
    if (CropVarietyStore.CropVarietiesPaginated !== undefined) {
      return CropVarietyStore.CropVarietiesPaginated;
    }
  }

  get isHidden() {
    return compareCrop.isHidden;
  }

  checked(e: any, id: number, name: string) {
    if (e) {
      compareCrop.addToSelectedCrops({ id: id, name: name, selected: true });
    } else {
      compareCrop.removeFromSelectedCrops(id);
    }
  }

  async created() {
    // if (this.$route.params.cropSlug) {
    //   this.cropSlug = String(this.$route.params.cropSlug);
    // }
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    let query = CropVarietyStore.Filter;
    query.page = this.pageNum;
    query.cropid = this.cropId;
    query.lang = this.$i18n.locale;
    await CropVarietyStore.getAllCropVarietiesDetail(query);
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    this.$router
      .replace({
        name: "ListingScreen",
        query: { page: String(this.pageNum) },
        params: { cropSlug: String(this.cropSlug) }
      })
      .catch(error => {});
  }
}
