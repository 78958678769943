









































































import { Vue, Component, Prop } from "vue-property-decorator";
import ListingCards from "@/components/UIComponents/ListingCards.vue";
import { CropVarietyFilter, CropVariety } from "@/store/models/cropVariety";
import CropVarietyStore from "@/store/modules/cropVariety";

@Component({
  components: {
    ListingCards: ListingCards
  }
})
export default class Listingbody extends Vue {
  @Prop({ default: "" })
  cropName!: string;

  @Prop({ default: "" })
  cropSlug!: string;

  @Prop({ default: 0 })
  cropId!: number;
  collapsable: any = [0, 1, 2];

  @Prop({ default: [] })
  filterables!: CropVarietyFilter[];

  activeCollapsable: any[] = [];

  get activeName() {
    if (this.activeCollapsable.length <= 0) {
      for (let filter in this.filterables) {
        if (Number(filter) < 3) {
          this.activeCollapsable.push(this.filterables[filter].id);
        }
      }
    }
    return this.activeCollapsable;
  }

  set activeName(value: any) {
    this.activeCollapsable = value;
  }

  filterCrops() {
    // let query: any = { cropid: this.cropId, page: 1, lang: this.$i18n.locale };
    this.cropSlug = String(this.$route.params.cropSlug);
    let query = CropVarietyStore.Filter;
    query.page = 1;
    query.cropid = this.cropId;
    query.lang = this.$i18n.locale;
    for (let filter of this.filterables) {
      if (filter.value && filter.queryparams && filter.value.length !== 0) {
        if (filter.querytype == "multicheckbox" && filter.value.length > 0) {
          query[filter.queryparams] = "[";
          for (let value of filter.value) {
            query[filter.queryparams] += '"' + value + '",';
          }
          query[filter.queryparams] += "]";
        } else if (filter.querytype == "range") {
          query[filter.queryparams] = "[" + filter.value + "]";
        } else {
          query[filter.queryparams] = filter.value;
        }
      }
    }
    CropVarietyStore.saveFilterQuery(query);
    CropVarietyStore.getAllCropVarietiesDetail(query);
    this.$router
      .replace({
        name: "ListingScreen",
        query: { page: String(1) },
        params: { cropSlug: String(this.cropSlug) }
      })
      .catch(error => {});
  }

  clearFilter() {
    this.cropSlug = String(this.$route.params.cropSlug);
    let filter = CropVarietyStore.Filter;
    let query: any = {
      cropid: this.cropId,
      page: 1,
      lang: this.$i18n.locale
    };
    if (filter.sorter) {
      query["sorter"] = filter.sorter;
    }
    CropVarietyStore.getAllCropVarietiesDetail(query);
    CropVarietyStore.getCropVarietyFilterables({
      cropId: this.cropId,
      lang: this.$i18n.locale
    });
    CropVarietyStore.saveFilterQuery(query);
    this.$router
      .replace({
        name: "ListingScreen",
        query: { page: String(1) },
        params: { cropSlug: String(this.cropSlug) }
      })
      .catch(error => {});
  }
}
